import * as React from "react";
import "../components/landingpage/desc-formation.scss";
import reactLogo from "../img/react-typescript-logo.png";
import reduxMiniLogo from "../img/redux-mini-logo.png";
import programmePDF3Fev from "../documents/programme - Le TDD et la Clean Architecture - Web - 19032025.pdf";
import {CommonLayoutFormation} from "../components/common-layout-formation";

const metaTitle = "Formation TDD et Clean Architecture avec React | WealCome";
const metaDescription = "Formez-vous au TDD et à la Clean Architecture dans le Web avec React et Typescript" +
    " en participant aux sessions de formation WealCome par Michaël AZERHAD.";

export default () => {
    return <CommonLayoutFormation metaTitle={metaTitle}
                                  metaDescription={metaDescription}
                                  title="Formation TDD et Clean Architecture dans le monde Web avec Typescript React et Redux (en option)"
                                  PodcastPresentation={() => {
                                      return <div className="row justify-content-center">
                                          <div className="col">
                                              <iframe width="100%" height="300" scrolling="no" frameBorder="no"
                                                      allow="autoplay"
                                                      src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/880654036&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=true"/>
                                          </div>
                                      </div>
                                  }}
                                  TextDescription={TextDescription}
                                  Schedule={() => <>
                                      <strong>Mercredi 19 mars - 09h15 / 17h00 <br/>et
                                          jeudi 20 mars 2025 - 09h15 / 17h00</strong>
                                  </>}
                                  ImagesTechnosLogo={() => <>
                                      <div>
                                          <img
                                              src={reactLogo}
                                              alt="React"
                                              className="img-fluid"
                                              width="40%"
                                              height="40%"
                                          />
                                          <img
                                              src={reduxMiniLogo}
                                              alt="Redux"
                                              className="img-fluid"
                                              width="60%"
                                              height="60%"
                                          />
                                      </div>
                                  </>}
                                  trainingType="React"
                                  programmePDFs={[programmePDF3Fev]}/>
};

const TextDescription = () => {
    return <>
        <h3>Descriptif</h3>
        <p>Les frontends comme les backends souffrent d’une complexité accidentelle dans la plupart des projets.</p>

        <p>Une complexité accidentelle est une complexité additionnelle malvenue qui aurait pu être évitée.</p>

        <p>Quand s’observe t'elle réellement ?<br/>
            Quelques mois après le début du projet.</p>

        <p>Pourquoi donc le terme “accidentelle” et non le terme “additionnelle” ?<br/>
            Car bien souvent, elle survient par surprise, sans anticipation aucune.<br/>
            Surtout lorsque le développeur n’est pas aguerri sur les concepts théoriques relatifs à la conception
            logiciel.</p>

        <p>Quelles en sont ses conséquences : <br/><br/>
            <ul>
                <li>- Un temps de développement considérablement augmenté pour la moindre fonctionnalité normalement
                    simple.
                </li>
                <li>- Une peur extrême de changer le code existant, de peur d’y engendrer des impacts non perçus ni maîtrisés.
                </li>
                <li>- Par cette peur de changer/casser, chaque bug remonté est corrigé avec des workarounds ;
                    autrement dit des pansements indirects proches de la supercherie.
                </li>
                <li>- Une expressivité du code réduite à néant, par à-coups de ces workarounds et du stress engendré
                    par la soumission face au code existant.
                </li>
                <li>- Un code qui devient de moins en moins testable, par des prises de raccourcis contraires aux
                    bonnes pratiques d’architectures et de code design souvent passées inaperçues, sans prise de
                    conscience.
                </li>
                <li>- Des sessions fatiguantes de débugging à outrance devant ce code jugé farfelu, non expressif et
                    souvent bancal.
                </li>
                <li>- Une application devenue très rapidement inflexible, dont même la moindre évolution
                    technologique telle une mise à jour de frameworks devient le signe d’un besoin de refonte
                    globale …
                </li>
            </ul>
        </p>

        <p>Est-ce une fatalité ?<br/>
            Devant le nombre de projets dans ce cas-là, il faut croire que oui.</p>

        <p>Mais il existe heureusement des pratiques logicielles qui redonnent de l’espoir et qui atténuent
            drastiquement cette complexité accidentelle si bien comprises et bien menées.</p>

        <p>Parmi ces pratiques, je mettrai l’accent sur deux d’entre-elles qui me paraissent totalement cruciales et
            qui ont changé mon quotidien dès 2011 y compris dans le monde du Front :</p>

        <p><ul>
            <li>- Le Test-Driven Development alias TDD</li>
            <li>- La Clean Architecture (cf Hexagonal Architecture ou Ports/Adapters architecture).</li>
        </ul></p>

        <p>Dans cette formation, je vous démontrerai comment réaliser un frontend from scratch avec ces pratiques dans un
            live coding avancé et fluide, sans manquer de vous exposer une bonne stratégie
            de tests combinant tests d’acceptation, tests unitaires, tests d’intégration et tests de composants orientés end-to-end_inmemory.</p>

        <p>Mon premier souhait : que vous compreniez bien que le TDD n’est pas une technique de test mais de codage
            permettant une amélioration notable de vos algorithmes et de vos designs.</p>

        <p>Stop les console.log intempestifs et les lancements de browsers fréquents, viens dans un monde où tu ne débuggueras quasiment plus jamais !</p>

        <p>Le sujet sera un sujet React/Typescript/Redux (ou sans Redux) digne de ce qu’on attend de nous en entreprise, bien loin d’un vulgaire Kata
            de tri de nombres.<br/></p>
        <p>Technologies :<br/><br/>
            <ul>
                <li>- React 18+ / Vite.js</li>
                <li>- Typescript, version courante</li>
                <li>- Redux (RTK) ou bien API Context si Redux n'est pas choisi, voire les 2 !</li>
                <li>- Jest</li>
            </ul>
        </p>

        <p>Ce sera interactif avec des exercices sur le chemin, des échanges de questions/réponses au tac au tac, et
            surtout une bonne ambiance, à la fois pro et détendue.</p>

        <p>Ayant l’habitude d’enseigner sur les sujets Craft, j’ai acquis une pédagogie qui vous plaira et qui ne
            laissera personne sur le carreau.</p>

        <p>Aussi, j’assurerai un suivi sous forme de réponses à vos nouvelles questions post-formation de sorte à ce
            que chacun d’entre-vous évoluent et progressent sans blocage et dans la bonne direction au
            quotidien.</p>

        <p>Pour finir, vous constaterez pour beaucoup que quasiment tout ce que vous pensiez au sujet de ces
            pratiques sont en réalité de fausses croyances ...</p>

        <p>Je n'ai plus qu'à vous souhaiter la bienvenue dans ce noble monde du développement logiciel professionnel.</p>

        <p><i>Les sessions regroupent entre 3 et 11 personnes, afin de garder une haute qualité d’intéraction.</i></p>

        <div className="program">
            <h2>Programme et méthode pédagogique</h2>
            <h3>Objectif du parcours pédagogique</h3>
            <ul>
                <li>
                    Sensibiliser les participants à la discipline du <strong>Test-Driven Development (TDD)</strong> et
                    de la <strong>Clean Architecture</strong> dans le monde Web afin de prévenir la complexité
                    accidentelle.
                </li>
                <li>
                    Développer des compétences en <strong>TDD</strong> et en <strong>Clean Architecture</strong> dans
                    l'écosystème <strong>React/TypeScript</strong> (et <strong>Redux</strong> si choisi) à travers des
                    sessions de live coding professionnelles et approfondies.
                    <br/>
                    Réaliser une application Front from scratch, conforme aux standards d'une entreprise.
                </li>
                <li>
                    Mettre en œuvre et comparer les différents types de tests
                    (<strong>unitaires</strong>, <strong>d'acceptation</strong>, <strong>end-to-end</strong>, <strong>d'intégration</strong>)
                    pour garantir la qualité du code.
                </li>
                <li>
                    Clarifier et démontrer les concepts clés et annexes à travers des séances interactives de
                    questions-réponses et des démonstrations concrètes.
                </li>
            </ul>
            <h3>Durée</h3>
            <p>14 heures</p>
            <h3>Date</h3>
            <p>
                <strong>Mercredi 19 mars - 09h15 / 17h00 <br/>et
                    jeudi 20 mars 2025 - 09h15 / 17h00</strong>
            </p>
            <h3>Lieu</h3>
            <p>En Zoom (Visio)</p>
            <h3>Public cible</h3>
            <p>Particuliers et professionnels : </p>
            <ul>
                <li>Technical Leader</li>
                <li>Développeur Frontend</li>
                <li>Développeur Full Stack</li>
                <li>Architecte technique</li>
            </ul>
            <h3>Pré-requis</h3>
            <ul>
                <li>Bonne maîtrise de Javascript et/ou Typescript</li>
                <li>Bonne maîtrise d'un framework orienté composants comme React, Angular ou Vue.js</li>
                <li>Bonnes connaissances en OOP et/ou Functional Programming</li>
                <li>Notions de Redux sont un plus</li>
                <li>Notions de RxJs sont un plus</li>
                <li>Capacité à écrire un simple test unitaire avec Jest ou Mocha</li>
            </ul>
            <h3>Déroulement du parcours pédagogique</h3>
            <h4>Jour 1</h4>
            <ul>
                <li>Rapide tour de table, présentation de chacun et exposition des attentes</li>
                <li>Introduction et cours théorique sur le TDD cassant les énormes quiproquos à son sujet</li>
                <li>Si le groupe est vraiment novice en TDD, Kata éventuel et judicieusement choisi de mises en pratique avec Node.js, Typescript et Jest</li>
                <li>Introduction et cours théorique sur la Clean Architecture</li>
                <li>Démarrage d'écriture d'une application "from scratch" digne d'un cas réel
                    d'entreprise en TDD (User Story avec plusieurs règles de gestion) tout en respectant la Clean Architecture dans le monde Web avec React/Redux?/Typescript.<br/>
                    Approche agile avec le mindset NoEstimates initiée par un mini atelier BDD judicieusement mené.</li>
                <li>Si option Redux choisie, introduction à celui-ci avec cassure des fausses croyances à son sujet</li>
                <li>Séances de questions / réponses tout au long</li>
            </ul>
            <h4>Jour 2</h4>
            <ul>
                <li>Suite du live coding de l'application "from scratch" digne d'un cas réel
                    d'entreprise
                </li>
                <li>Séances de refactoring au fil de l'eau du code ET des tests
                    de l'application exemple, rendues plaisantes et sans crainte grâce au TDD
                </li>
                <li>Clarification de concepts subtils relatifs au TDD et à la Clean Architecture</li>
                <li>Démonstration de la pratique efficace de TDD sur des composants React sous la forme de test
                    "end-to-end InMemory"
                </li>
                <li>Compréhension du concept de "test d'intégration"</li>
                <li>Séance de questions / réponses tout au long</li>
            </ul>
            <h3>Capacités développées</h3>
            <ul>
                <li>Maîtrise de l'approche des problèmes algorithmiques avec le mindset TDD</li>
                <li>Maîtrise de l'écriture des tests unitaires orientés comportements, de tests
                    d'acceptation, de tests d'intégration et de tests end-to-end
                </li>
                <li>Maîtrise du concept d'inversion de dépendances (DIP) dans le monde Web</li>
                <li>Maîtrise de la Clean Architecture (Hexagonale Architecture) et de l'arborescence de
                    fichiers optimale
                </li>
                <li>Si Redux a été choisi, bonnes connaissances de Redux et de Redux-Thunk (éventuellement Redux-Observable si le groupe en fait la demande)
                </li>
                <li>
                    Maîtrise du concept de View Models avec éventuellement les selectors de Redux (et avec Reselect),
                    facilitant
                    grandement les tests et le découplage avec React
                </li>
                <li>Capacité à refactorer des tests astucieusement (techniques de chapitrage / nommage / D.R.Y), essentielle pour évoluer sereinement
                    en TDD
                </li>
            </ul>
            <h3>Démarche et méthodes proposées</h3>
            <p>
                La formation se présentera sous la forme d'une alternance entre
                découverte de concepts théoriques, annihilation de fausses croyances,
                démonstrations complètes de l'approche par du live coding, exercices et échanges constants avec les participants.
            </p>
            <p>
                L'outil de communication requis pour cette formation est l'alternance
                entre l'oral, l'écrit, le partage d'écran si visio (audio/video) et la
                manipulation d'un éditeur de code.
            </p>
            <h3>Les moyens pédagogiques</h3>
            <ul>
                <li>Des apports théoriques sur le processus</li>

                <li>Des exemples concrets</li>

                <li>Des démonstrations complètes par le formateur en live coding</li>

                <li>Exercices réalisés en live par les participants afin de s'exercer sur cette application
                    d'entreprise.
                </li>

                <li>Challenges proposés quant au TDD, Clean Archi et au refactoring de code (modification de
                    structure du code)
                </li>
            </ul>
            <h3>Évaluation formative</h3>
            <p>
                Une feuille d'évaluation de compétences est remise à chaque stagiaire
                leur permettant d'évaluer leurs acquis.
            </p>
            <p>
                Une deuxième évaluation permet d'évaluer les modalités pédagogiques et
                le contenu de la formation, de mesurer le degré de satisfaction à
                chaud du stagiaire pour adapter les formations suivantes aux besoins
                spécifiques des participants.
            </p>
            <h3>Documents et supports pour les stagiaires</h3>
            <p>
                À l’issue de cette session, il sera remis à chaque stagiaire le
                support de formation, le projet sur Git réalisé ensemble, ainsi qu’une
                certification de réalisation de l’action de formation.
            </p>
            <h3>Encadrement pédagogique</h3>
            <p>
                La formation sera assurée par{" "}
                <a href="/#fondateur" target="_blank" rel="noopener noreferrer">
                    Michaël AZERHAD
                </a>{" "}
                <br/>
                Profil et qualité : Président de WealCome et expert technique de 20 ans d'expérience<br/>
            </p>
            <h3>Modalité d'accès</h3>
            <p>
                Contactez-nous au <a href="tel:+33609885102">06 09 88 51 02</a> ou par
                mail :&nbsp;
                <a href="mailto:contact@wealcomecompany.com">
                    contact@wealcomecompany.com
                </a>
            </p>
            <h3>Délais d'accès</h3>
            <p>
                Nous pouvons programmer les formations en fonction de vos contraintes et de
                nos disponibilités.
            </p>
            <p>
                Nos formations sont accessibles aux personnes en situation de
                handicap.
                <br/>
                Les aspects, l’accessibilité et le type de handicap au regard des
                modalités pédagogiques sont à évoquer impérativement au cours de
                l’entretien préalable à toute contractualisation afin de pouvoir
                orienter ou accompagner au mieux les personnes en situation de
                handicap.
            </p>
        </div>
    </>
}
